.modal-wrapper{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 4;
  background-color: rgba(169, 169, 169, 0.5);
  font-family: 'Open Sans', sans-serif;
}

.modal-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #fff;
  position: fixed;
  width: 16rem;
 padding: 18px;
 padding-bottom: 22px;
 border-radius: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-wrap: pretty;
}


.ok{
  width: 60px;
  align-self: center;
  margin-bottom: 10px;
}

.modal-container p{
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.okButton{
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  background-color: #339af0;
  color: #ffffff !important;
  margin-top: 22px;
  padding: 6px 30px;
  border: 0;
}

#modal_success{
  font-size: 19px;
  font-weight: 900;
  color:  #339af0;
}

.success_message{
  font-size: 15px;
  color: gray;
  font-weight: 600;
}

@media only screen and (max-width : 768px){
 
  .ok{
    width: 60px;
    align-self: center;
  }
}

/* Error */

.error-wrapper{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 4;
  background-color: rgba(169, 169, 169, 0.5);
  font-family: 'Open Sans', sans-serif;
}

.error-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #fff;
  position: fixed;
  width: 16rem;
 padding: 18px;
 padding-bottom: 22px;
 border-radius: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-wrap: pretty;
}


.error{
  width: 60px;
  align-self: center;
  margin-bottom: 10px;
}

.error-container p{
  width: 100%;
  font-size: 12px;
  text-align: center;
}

#modal_error{
  font-size: 19px;
  font-weight: 900;
  color:  #FA5252;
}

.error_message{
  font-size: 15px;
  color: gray;
  font-weight: 600;
}

.errorButton{
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  background-color: #FA5252;
  color: #ffffff !important;
  margin-top: 22px;
  padding: 6px 30px;
  border: 0;
}

