    .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This will center the spinner vertically */
  }
  
  .spinner {
    height: 5vh;
    width: 5vh;
    border: 4px dotted;
    border-color: transparent #4285f4 transparent #4285f4 ;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  


  