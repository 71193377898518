.AProd-root {
  background-color: #fff;
}

.category-heading-allProduct {
  font-size: 19px;
  font-weight: 500;
  color: rgb(13, 13, 19);
  padding: 20px 0;
  padding-left: 12px;
}

.linkPath {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.details-skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.listProjects::-webkit-scrollbar {
  display: none;
}

.mainbox-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 94%;
  border-radius: 6px;
}

.product-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(242, 246, 254);
  padding: 15px 5px;
}

.product-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 10px;
}
 

.logo {
  width: 32px;
  height: 32px;
}

.project-heading {
  font-size: 14px;
  font-weight: 500;
  color: rgb(4, 4, 64);
  width: 90%;
  padding-left: 9px ;
  align-self: center;
}
.usp-box {
  padding: 10px 10px 6px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.usp-box-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.usp-image{
  padding-left: 6px;
}
.usp-image img {
  width: 15px;
  height: 15px;
  margin-bottom: 4px;
}

.usp-info {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px ;
  
}

.usp-info p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  color: #4285f4;
  text-wrap: wrap;
}



@media only screen and (min-width: 768px) {

   .category-heading-allProduct {
      width: 49%;
      margin: 0 auto;
      padding-left: 0;
    }

  .listProjects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 62px;
    margin-bottom: 0 !important;
    overflow: scroll;
    height: calc(100vh -56px);
  }

  .listProjects::-webkit-scrollbar {
    display: none;
  }

  .listHoldProjects {
    width: 100%;
    margin: 2px auto 10px auto !important;
  }

  .details-skeleton {
    display: flex;
    align-self: center;
    margin: 0 auto;
    width: 50%;
  }
  .mainbox-page {
    margin: 0 auto 10px auto;
    width: 50%;
  }
}
