.skeleton-slider {
   height: 180px;
   width: 92%;
   border-radius: 8px;
   margin: 0 auto;
   animation: skeleton-slider-loading 1s linear infinite alternate;
 }
 
 @keyframes skeleton-slider-loading {
   0% {
     background-color: hsl(200 , 20% , 70%)
   }
   25% {
     background-color: hsl(120, 20%, 95%)
   }
   50% {
      background-color: hsl(200, 100%, 97%)
    }
    75% {
      background-color: hsl(180, 1%, 87%)
    }
    100% {
      background-color: hsl(0, 5%, 92%)
    }
 }

 .shimmer-name {
  height: 10px;
  width: 95%;
  margin: 0 auto;
  animation: name-loading 1s linear infinite alternate;
}

@keyframes name-loading {
  0% {
    background-color: hsl(200 , 20% , 70%)
  }
  25% {
    background-color: hsl(120, 20%, 95%)
  }
  50% {
     background-color: hsl(200, 100%, 97%)
   }
   75% {
     background-color: hsl(180, 1%, 87%)
   }
   100% {
     background-color: hsl(0, 5%, 92%)
   }
}

.products-shimmer {
  height: 100px;
  width: 92%;
  border-radius: 6px;
  margin: 6px auto;
  animation: product-loading 1s linear infinite alternate;
}

@keyframes product-loading {
  0% {
    background-color: hsl(200 , 20% , 70%)
  }
  25% {
    background-color: hsl(120, 20%, 95%)
  }
  50% {
     background-color: hsl(200, 100%, 97%)
   }
   75% {
     background-color: hsl(180, 1%, 87%)
   }
   100% {
     background-color: hsl(0, 5%, 92%)
   }
}


.shimmer-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 92%;
  margin: 0 auto;
}

.skeleton-trending-project{
  width: 48%;
  height: 70px;
  border-radius: 8px;
  animation: trendingProduct 1s linear infinite alternate;
}
#tp-shimmer-3 , #tp-shimmer-4{
  display: none;
  visibility: hidden;
}

@keyframes trendingProduct {
  0% {
    background-color: hsl(200 , 20% , 70%)
  }
  25% {
    background-color: hsl(120, 20%, 95%)
  }
  50% {
     background-color: hsl(200, 100%, 97%)
   }
   75% {
     background-color: hsl(180, 1%, 87%)
   }
   100% {
     background-color: hsl(0, 5%, 92%)
   }
}

 @media only screen and (min-width: 768px) {
   .skeleton-slider {
     margin: 0 auto;
     width: 90%;
     height: 170px;
     border-radius: 16px;
     z-index: 1;
   }
    .products-shimmer{
        width: 50%;
    }

    .skeleton-trending-project{
      width: 22%;
      height: 70px;
      border-radius: 8px;
      animation: trendingProduct 1s linear infinite alternate;
    }

    #tp-shimmer-3 , #tp-shimmer-4{
      display: block;
      visibility: visible;
    }
   
 }