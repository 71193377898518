.alertDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    border: solid gray 2px;
    padding: 5px;
    border-radius: 5px;
    color: black;
    background-color: aliceblue;
}

.onHoldDiv{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: sans-serif;
}

.errImg, .failureImg {
    width: 30%;
    height: auto;
}

.invalidurlmessage{
    font-family: sans-serif;
}

.failureDiv {
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: sans-serif;
}

p {
    margin-bottom: 0rem;
    font-size: 13px;
}

.alertBtnDiv {
    display: inline-block;
}

.AlertBtnCancel,
.AlertBtnProceed {
    margin-right: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;

}

.blueSpinner {
    width: 50%;
    max-width: 100px;
    height: auto;
}

.existImg {
    width: 10%;
    height: auto;
}