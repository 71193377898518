.partner-container{
  /* background: linear-gradient(to bottom , rgb(240, 251, 255) ,rgb(239, 249, 255) , rgb(248, 254, 255)); */
  background: linear-gradient(to bottom , #f4f7fe ,#f0f6fe , #eff3fb);
  padding: 20px 0;
}

.h4-partner-title{
  display: block;
  margin:  0 auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: rgb(13, 13, 19);
}

.p-partners{
  display: block;
  margin:  0 auto;
  text-align: center;
  font-size: 15px;
  line-height: 30px;
}

.partner-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  padding: 10px 0;
}

.partner-item{
  display: grid;
  width: 80px;
  height: 80px;
  justify-self: center;
  
}



.partner-item img{
width: 80px;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  justify-self: center;
  align-self: center;
}

.custom-size img{
  width: 120px;
}

@media only screen and (min-width : 768px){
  .h4-partner-title{
   font-size: 22px;
  }
}

