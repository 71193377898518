.enquiry-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to bottom , #f4f7fe ,#f0f6fe , #eff3fb);
  margin-top: 30px;
  padding: 40px 0;

}

.enquiry-image{
  display: flex;
  justify-content: center;
  align-items: center;
}

.enquiry-input-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 15px;
  column-gap: 15px;
  width: 90% !important;
}

.enquiry-image img{
  width: 350px;
}

.enquiry-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

.enquiry-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}



.h5-enquiry {
  font-size: 22px;
  color: black;
  width: 100%;
}

.p-enquiry {
  display: flex;
  font-size: 15px;
  width: 100%;
  color: rgb(90, 85, 85);
  margin-bottom: 15px;
}

.enquiry-input-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

#enquiry-button {
  padding: 6px 16px;
  border-style: none;
  background-color: #4285f4;
  color: #fff;
  border-radius: 4px;
}

#bottomInput::placeholder {
  color: gray;
}

#bottomInput {
  width: 100% ;
  padding: 6.5px;
  border-radius: 6px;
  border: 0.5px solid rgb(128, 128, 128 , 0.5) !important;
  font-size: 14px;
  background-color: white;
}
.tel{
  padding: 5.26px;
}

#selectedCategory{
  margin-top: 1px;
  width: 100% ;
  padding: 6.6px 2px;
  font-size: 14px;
  border-radius: 6px;
  border: 0.5px solid rgb(128, 128, 128 , 0.5) !important;
  outline-style: none;
  background-color: white;
}

#mobileInput{
  margin-top: 0.7px;
  width: 100% ;
  padding: 6.5px;
  font-size: 14px;
  border-radius: 6px;
  border: 0.5px solid rgb(128, 128, 128 , 0.5) !important;
  outline-style: none;
  background-color: white;
  color: black !important;
}

#mobileInput::placeholder{
  color: gray;
}



@media only screen and (max-width: 768px) {

  .enquiry-wrapper {
    flex-direction: column;
    width: 100%;
    padding-top: 0;
    padding-bottom: 40px;
  }

  .enquiry-image img{
    width: 280px;
  }

  .enquiry-box{
    width: 100%;
    }
  
  

  .enquiry-tag {
    width: 92%;
  }

  .h5-enquiry {
    font-size: 18px;
   font-weight: 500;
   color: rgb(13, 13, 19);
   margin-bottom: 20px;
  }

  .p-enquiry {
    display: flex;
    font-size: 15px;
     width: 100%;
     color: rgb(90, 85, 85);
  }

  .enquiry-input-box {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 10px;
    width: 92% !important;
  }



  #enquiry-button {
    width: 92% !important;
  }

  #selectedCategory{

    padding: 7px 2px;
   
  }
}
