/* title css */
.tp-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  margin-top: 30px;
}

.tp-title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.tp-title h4 {
  font-size: 18px;
  font-weight: 500;
  color: rgb(13, 13, 19);
  margin-bottom: 20px;
}

.trendig-project-wrapper {
  display: block;
  margin: 0 auto;
}
.trendingProject-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

#trending_link {
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.trendingProject-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 20px 10px;
  width: 89%;
  height: 70px;
  border-radius: 8px;
  margin-bottom: 5px;
}

.trendingProject-details {
  display: inline-block;
  justify-items: center;
  width: 80%;
}

#brandName {
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: black;
}

#brandCategory {
  color: rgb(13, 13, 19);
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
}

.trendingProject-Image {
  width: 30px;
  height: 30px;
}

.trendingProject-Image img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .tp-header {
    padding-left: 0;
  }

  .tp-title {
    display: flex;
    justify-content: flex-start;
    width: 92%;
  }

  .tp-title h4 {
    font-size: 20px;
    font-weight: 500;
    color: rgb(13, 13, 19);
    margin-bottom: 20px;
  }

  .trendig-project-wrapper {
    width: 94%;
  }
  .trendingProject-box {
    width: 70%;
  }

  #trending_link {
    text-decoration: none;
  }

  .trendingProject-inner {
    padding: 40px 10px 40px 10px;
    width: 90%;
  }

  .trendingProject-details {
    display: inline-block;
    justify-items: center;
    width: 80%;
  }

  #brandName {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: black;
  }

  #brandCategory {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: rgb(13, 13, 19);
    font-weight: 500;
  }

  .trendingProject-Image {
    display: inline-block;
    width: 40px;
    height: 40px;
  }

  .trendingProject-Image img {
    width: 100%;
  }
}
