.certificate-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* background: linear-gradient(to bottom , #f4f5fa , #f4f5fa  , #cfd0d1); */
  background-image: linear-gradient(
      45deg,
      #f4f7fe 25%,
      rgba(239, 239, 239, 0) 25%,
      rgba(239, 239, 239, 0) 75%,
      #f0f6fe 75%,
      #eff3fb
    ),
    linear-gradient(
      45deg,
      #efefef 25%,
      rgba(239, 239, 239, 0) 25%,
      rgba(239, 239, 239, 0) 75%,
      #efefef 75%,
      #efefef
    );
  color: black;
  padding: 30px 0 10px 1px;
  text-wrap: pretty;
}

.certificate-info {
  width: 100%;
  padding: 10px 12px;
}



 #explore-products  {
  font-weight: bold;
  font-size: 14px;
  color: #4285F4;
}



.agent-info{
  font-size: 24px;
  color: black;
  font-weight: bold;
  
}

#agent-detail{
  font-size: 22px;
  color: gray;
  font-weight: bold;
}

#agentname , #agentdesignation{
  color: #4285F4;
  font-weight: bold;
  font-size: 24px;
}

.certificate-info p {
  font-size: 14px;
  color: black;
}

.agent-message{
  /* font-family: 'Rubik', sans-serif; */
  /* font-weight: bold; */
  font-weight: 500;
}
.agent-message , .explore{
  margin-top: 10px;
  
}

.explore p{
  color: black;
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  .certificate-wrapper {
    padding: 5px 0;
    background: transparent;
  }

  .certificate-info {
    width: 90%;
  }

  .certificate-info p {
    font-size: 16px;
  }

  .agent-info{
    font-size: 30px;
  }

  #agentname , #agentdesignation{
    font-size: 30px;
  }

  .explore p{
    color: black;
    font-size: 16px;
    font-weight: 500;
  }

  .agent-message{
    font-size: 16px;
  }

  #explore-products  {
    font-weight: bold;
    font-size: 16px;
    color: #4285F4;
  }
}
