.f-wrapper {
  margin-top: 30px;
}

.f-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 14px;
  margin-top: 30px;
}

.f-title {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.f-title h4 {
  font-size: 18px;
  font-weight: 500;
  color: rgb(13, 13, 19);
  margin-bottom: 15px;
}


.f-box {
  display: grid;
  justify-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  row-gap: 12px;
  column-gap: 12px;
}


.f-item {
  text-decoration: none;
  color: black;
  text-align: center;
}

.f-logo {
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 5px 5px 5px;
  font-size: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: rgb(13, 13, 19);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

#airtel-5g {
  background-color: #fff;
}

#flipkart_logo {
  background-color: green;
}

#medibuddy {
  background-color: #beef54;
}

#instamart {
  background-color: rgb(246, 223, 181);
}

#f-name {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  margin: 8px auto 0 auto;
  width: 70px;
}

@media only screen and (min-width: 768px){
  .f-header {
    padding-left: 0;
  }

  .f-title {
    display: flex;
    justify-content: flex-start;
    width: 92%;
  }

  .f-title h4 {
    font-size: 20px;
  }

}

@media only screen and (min-width: 992px) {


  #f-name {
    font-weight: 500;
  }

  .f-container{
    display: flex;
    justify-content: flex-start;
    padding-left: 30px;
  }

  .f-box-inner{
    padding: 10px 20px;
    margin: 0 50px 0 0;
  }
  
  .f-box {
    display: flex;
    flex-wrap: wrap;
    width: 92%;
  }


  .f-item {
    text-decoration: none;
    color: black;
    text-align: center;
  }

  .f-logo {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    /* padding: 10px 10px 10px; */
    /* font-size: 12px; */
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    color: rgb(13, 13, 19);
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
}


