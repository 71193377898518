.container-body {
  background: #fff;
}

.Slider {
  margin-top: 30px;
}

/* Website-Header CSS */
.Header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  background-color: #fff;
}

.aryo-partner {
  color: #4285f4;
  font-size: 17px;
  font-weight: 600;
  margin-left: -10px;
}

/* Benefits CSS */

.benefits-wrapper {
  display: flex;
  flex-direction: row-reverse;
  padding: 60px 0 80px 0;
  margin-top: 30px;
  width: 100%;
  justify-content: space-around;
  z-index: 10;
  background-color: #fff;
}

.sales-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sales-logo img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-image: radial-gradient(
    rgb(247, 253, 255),
    rgb(227, 244, 255),
    rgb(132, 222, 238)
  );
}

.benefits-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

#benefits-title {
  font-size: 22px;
  font-weight: 500;
  color: rgb(13, 13, 19);
  margin-bottom: 20px;
}

#info-para {
  line-height: 2.1;
  font-size: 17px;
  font-weight: 400;
  width: 100%;
}

#info-para img {
  width: 18px;
  height: 18px;
  position: relative;
  bottom: 1px;
}

/* footer */

.conditions {
  display: block;
  margin: auto;
  background-color: rgb(38, 38, 38);
  padding: 6px 0;
}

.terms-policy {
  width: 100%;
  text-align: center;
}

#copyright {
  font-size: 15px;
  color: white;
}

#terms {
  font-size: 13px;
  color: #4285f4;
}

.termsLink {
  text-decoration: none;
}

#policy {
  font-size: 13px;
  color: #4285f4;
}

.contact-box {
  display: flex;
  padding: 6px;
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 5;
}

#whatsapp {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-bottom: 5px;
}

#call {
  width: 28px;
  height: 28px;
  /* margin-top: 2px; */
}

.openwatsapp {
  display: flex;
  border: 0px;
  outline-style: none;
  background: transparent;
}

@media only screen and (max-width: 468px) {
  .conditions {
    display: block;
    margin: auto;
    background-color: rgb(38, 38, 38);
    padding: 16px 0;
  }
}

@media only screen and (max-width: 768px) {
  .benefits-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 40px;
    width: 100%;
    z-index: 10;
    background-color: #fff;
  }

  .benefits-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: 15px;
  }

  #benefits-title {
    font-size: 18px;
    font-weight: 500;
    color: rgb(13, 13, 19);
    margin-bottom: 20px;
  }

  #info-para {
    line-height: 2;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
  }

  #info-para img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 1px;
  }

  .sales-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .sales-logo img {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: radial-gradient(
      rgb(247, 253, 255),
      rgb(227, 244, 255),
      rgb(132, 222, 238)
    );
    opacity: 1;
  }

  #info-para {
    line-height: 2;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
  }

  #info-para img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 1px;
  }

  #terms {
    font-size: 13px;
    color: #4285f4;
    text-decoration: none;
  }
  .termsLink {
    text-decoration: none;
  }

  #policy {
    font-size: 13px;
    color: #4285f4;
  }

  #copyright {
    font-size: 14px;
    padding-top: 5px;
  }

  .trending-project {
    background-size: contain;
  }

  .trending-project img {
    background-size: contain;
    height: 100px;
    width: 350px;
  }
}

/* CS Container */

@media only screen and (min-width: 768px) {
  .cs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .certificate {
    width: 60%;
  }

  .certificate-image {
    display: none;
    visibility: hidden;
  }

  .Slider {
    width: 33.5%;
  }
}
