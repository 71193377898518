.mainbox {
    display: block;
    margin: auto;
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    
}



.invalidUrl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.errImg{
    width: 100%;
}

.invalidurlmessage{
    font-size: 16px;
}



.groupfield {
    border: 0px;
    margin-top: 20px;
}

.dropdown-flex {
    background-color: whitesmoke;
    display: flex;
    width: 80%;
    margin: auto;
    font-size: 13px;
    flex-direction: column;
    border: 0px solid gray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 200px;
    overflow: auto;
}

.dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
}

input[type='text'] {
    display: block;
    margin: auto;
    width: 80%;
    border-radius: 5px;
    margin-top: 10px;
    background: lightsteelblue;
    border: 1px;
    box-shadow: 2px;
    color: black;
    background-color: rgb(223, 223, 235);
    padding: 10px;
    font-size: 15px;
    font-family: sans-serif;
}

input[type='email'] {
    display: block;
    margin: auto;
    width: 80%;
    border-radius: 5px;
    background: lightsteelblue;
    border: 1px;
    margin-top: 10px;
    box-shadow: 2px;
    color: black;
    background-color: rgb(223, 223, 235);
    padding: 10px;
    font-size: 15px;
    font-family: sans-serif;
}

input[type='number'] {
    display: block;
    margin: auto;
    width: 80%;
    border-radius: 5px;
    margin-top: 10px;
    background: lightsteelblue;
    border: 1px;
    box-shadow: 2px;
    color: black;
    background-color: rgb(223, 223, 235);
    padding: 10px;
    font-size: 15px;
    font-family: sans-serif;
}

.citicardcities {
    display: block!important;
    margin: auto!important;
    width: 85%;
    margin-top: 20px;
    border-radius: 5px;
    background: rgb(223 223 235);
    border: 1px;
    box-shadow: 2px;
    font-size: 15px;
}

.dropdown {
    display: block;
    margin: auto;
    width: 85%;
    margin-top: 20px;
    border-radius: 5px;
    background: rgb(223 223 235);
    border: 1px;
    box-shadow: 2px;
    color: lightseagreen;
    padding: 10px;
    font-size: 15px;
}
.error {
    display: block;
    margin: auto;
    width: 85%;
    margin-top: 4px;
    font-size: 12px;
    color: red;
}

::placeholder {
  
    color: rgb(185, 146, 146);
    opacity: 1;
  
}

.btn-submitlead {
    display: block;
    margin: auto;
    width: 60%;
    margin-top: 26px;
    text-align: center;
    background-color: #4285F4;
    color: white;
    border: 0px;
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 5px;
}

.prodlogo {
    height: 25%;
    width: 25%;
    display: block;
    margin: auto;
}

.cclogo {
    display: block;
    margin: auto;
    width: 40%;
}

#taglineId {
    display: block;
    width: 80%;
    margin: auto;
    margin-top: 10px;
    text-align: center;
    font-family: sans-serif;
}

.taglineheader {
    text-align: center;
    font-size: 16px;
    color: gray;
}

.tooltiptext {
    visibility: hidden;
    width: 140px;
    display: block;
    margin: auto;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .copycouponbtn {
    display: block;
    margin: auto;
    margin-top: 1px;
    color: black;
    background-color: white;
    border-radius: 5px;
  }

  .coupon {
    margin-top: 0rem;
    text-align: center;
    font-size: 20px;
    width: 60%;
    color: black;
    border-color: gray;
    border-style: dashed;
    border-width: .2px;
    font-family: monospace;
    background-color: aliceblue;
    margin-bottom: 0rem!important;
}

.coupondiv {
    margin-top: 1rem;
    display: inline-flex;
    justify-content: center;
    width: 100%;
}

.copyimg {
    height: 30px;
    width: 30px;
}

.giottuslogo {
    height: 50%;
    width: 100%;
}
@media (max-width: 768px) {

    .mainbox {
        display: block;
        margin: auto;
        width: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
        
    }

  
}