.loader-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh; /* This will center the spinner vertically */
 }
 
 .loader {
   height: 3vh;
   width: 3vh;
   border: 2px solid;
   border-color:  #4285f4 transparent #4285f4 ;
   border-radius: 50%;
   animation: spin 1s linear infinite;
  
 }
 
 @keyframes spin {
   to {
     transform: rotate(360deg);
   }
 }
 


 


 