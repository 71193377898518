.categories-container {
    display: block;
    margin: auto;
    width: 100%;
    padding: 10px 0;
    position: relative;
    z-index: 1;
    /* background-color: #FFF; */
    /* background: linear-gradient(to bottom , #f4f7fe ,#f0f6fe , #eff3fb); */
    box-sizing: border-box;

  }

  .category-heading-loan {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    width: 100%;
  }
  
  .category-heading-loan p{
    font-size: 17px;
    font-weight: 500;
    color: rgb(13, 13, 19);
    margin-bottom: 10px;
  }

  .category-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 14px;
    margin-top: 30px;
  }

  .category-title {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .category-title h4 {
    font-size: 18px;
    font-weight: 500;
    color: rgb(13, 13, 19);
    margin-bottom: 20px;
  }
  
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    row-gap: 16px;
    column-gap: 12px;
  }
  
  
  .grid-item {
    display: block;
    margin: auto;
    width: 90px;
    height: 90px;
    position: relative;
    z-index: 1;
    padding: 10px 10px 10px;
    text-align: center;
    font-size: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(13, 13, 19);
    border-radius: 8px;
  }
  
  .linkClass {
    text-decoration: none;
  }
  
  .categoryImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 30px;
    align-items: center;
  }
  
  .categoryName {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    text-align: "center";
    font-weight: 500;
    margin: 4px auto 8px;
  }
  
  
  @media only screen and (min-width:768px) {
    .category-header {
      padding-left: 0;
    }
  
    .category-title {
      display: flex;
      justify-content: flex-start;
      width: 92%;
    }

    .category-title h4 {
      font-size: 20px;
      
    }

    .categoryName {
      font-weight: 500;
    }


      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        row-gap: 16px;
        column-gap: 12px;
      }
      
      
      .grid-item {
        display: block;
        margin: auto;
        width: 90px;
        height: 90px;
        position: relative;
        z-index: 1;
    
        padding: 10px 10px 10px;
        text-align: center;
        font-size: 12px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: rgb(13, 13, 19);
        border-radius: 8px;
      }
  }